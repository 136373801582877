import { render, staticRenderFns } from "./PreHomeV2.vue?vue&type=template&id=6c913fe0&scoped=true"
import script from "./PreHomeV2.vue?vue&type=script&lang=js"
export * from "./PreHomeV2.vue?vue&type=script&lang=js"
import style0 from "./PreHomeV2.vue?vue&type=style&index=0&id=6c913fe0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c913fe0",
  null
  
)

export default component.exports