<template>
  <div class="session-item card search__card">
    <router-link v-if="!ressourcesMovedTo365" :to="`/${eventRoute}/sessions/${result.source.furl}`" class="d-block stretched-link" style="height: 0"></router-link>
    <a v-else :href="`${esc365SessionUrl(result.source.dbId)}`" class="d-bloc stretched-link" style="height: 0"></a>
    <!-- 
    ===============================================================================================
    CARD HEADER
    ===============================================================================================
     -->
    <div class="card-header">
      <div class="d-flex pb-2">
        <div v-if="result.source && result.source.startTimeUnixEpoc" class="session__time font-bold">
          {{ result.source.startTimeUnixEpoc | formatUnixEpocDayPart }} • {{ result.source.startTimeUnixEpoc | formatUnixEpocTimePart }}
          -
          {{ result.source.endTimeUnixEpoc | formatUnixEpocTimePart }}
        </div>

        <span v-else class="search__card__header__date">On Demand</span>
        <span class="session__card__header_live-now" v-if="showLiveBadge && session.isLive">live</span>

        <div
          v-if="result.source.digitalSessionIds && result.source.digitalSessionIds && result.source.title"
          class="session__favorite__button"
          data-v-step-search="4"
        >
          <FavoriteButton
            :title="result.title"
            :digitalSessionId="parseInt(result.source.digitalSessionIds[0]?.substring(1))"
            :scientificSessionId="parseInt(result.source.dbId)"
            width="20"
            height="20"
          />
        </div>
      </div>

      <div class="d-block text-left lh-1">
        <div class="session__type d-inline-flex align-items-center font-regular">
          <span class="mr-1 material-icons-outlined"> co_present </span> <span>{{ result.source.type }}</span>
        </div>
      </div>
      <div class="d-block text-left lh-1">
        <div class="session__channel d-inline-flex align-items-center font-regular mr-2">
          <span class="mr-1 material-icons-outlined" v-if="result.source.roomDisplayName"> place </span>
          <span>{{ this.showRoom(result.source.roomDisplayName, result.source.channel) }}</span>
        </div>

        <div class="session__location--online d-inline-flex align-items-center font-regular">
          <span class="mr-1 material-icons-round" v-if="result.source.channel"> cast </span>
          <span>{{ this.showChannelRoom(result.source.roomDisplayName, result.source.channel) }}</span>
        </div>
      </div>
    </div>
    <!-- 
    ===============================================================================================
    CARD BODY
    ===============================================================================================
     -->
    <div class="card-body search__card__body">
      <div class="" v-if="!isSponsoredSessionType(result.source)">
        <h4 class="card-title" v-if="result.source.title" v-html="result.highlight['title.text'] ? result.highlight['title.text'][0] : result.source.title"></h4>
        <div class="search__card__body__subtitle" v-if="result.source.subTitle">
          {{ result.source.subTitle }}
        </div>
        <!-- Snippets -->
        <!-- Presentation Title -->
        <blockquote class="search__card__body__snippets pt-4" v-if="result.highlight['presentations.text']">
          <span>Presentation: </span>
          <span v-html="result.highlight['presentations.text'][0]"></span>
        </blockquote>
        <!-- Session LearningObjectives -->
        <blockquote class="search__card__body__snippets text-white-space-pre-wrap pt-4" v-else-if="result.highlight['learning_objectives']">
          <span>More on the session: </span>
          <span v-html="result.highlight['learning_objectives'][0]"></span>
        </blockquote>
        <!-- Topic Tags -->
        <blockquote class="search__card__body__snippets pt-4" v-else-if="result.highlight['topic.text']">
          <span>Topic: </span>
          <span v-html="result.highlight['topic.text'][0]"></span>
        </blockquote>
        <!-- Contributor -->
        <blockquote class="search__card__body__snippets pt-4" v-else-if="result.highlight['contributors.fullname.text']">
          <span>Contributor:</span>
          <span v-html="result.highlight['contributors.fullname.text'][0]"></span>
        </blockquote>
      </div>
      <div v-else>
        <div class="sponsored-session">
          <div class="sponsored-front">
            <div class="session__title font-black lh-1 mt-2">Industry Session</div>
          </div>
          <div class="sponsored-back">
            <h4 class="card-title" v-if="result.source.title" v-html="result.highlight['title.text'] ? result.highlight['title.text'][0] : result.source.title"></h4>
            <div class="search__card__body__subtitle" v-if="result.source.subTitle">
              {{ result.source.subTitle }}
            </div>
            <!-- Snippets -->
            <!-- Presentation Title -->
            <blockquote class="search__card__body__snippets pt-4" v-if="result.highlight['presentations.text']">
              <span>Presentation: </span>
              <span v-html="result.highlight['presentations.text'][0]"></span>
            </blockquote>
            <!-- Session LearningObjectives -->
            <blockquote class="search__card__body__snippets text-white-space-pre-wrap pt-4" v-else-if="result.highlight['learning_objectives']">
              <span>More on the session: </span>
              <span v-html="result.highlight['learning_objectives'][0]"></span>
            </blockquote>
            <!-- Topic Tags -->
            <blockquote class="search__card__body__snippets pt-4" v-else-if="result.highlight['topic.text']">
              <span>Topic: </span>
              <span v-html="result.highlight['topic.text'][0]"></span>
            </blockquote>
            <!-- Contributor -->
            <blockquote class="search__card__body__snippets pt-4" v-else-if="result.highlight['contributors.fullname.text']">
              <span>Contributor:</span>
              <span v-html="result.highlight['contributors.fullname.text'][0]"></span>
            </blockquote>
          </div>
        </div>
      </div>
    </div>
    <!-- 
      ===============================================================================================
      CARD FOOTER
      ===============================================================================================
      -->
    <!-- View action to verify -->
    <div class="card-footer search__card__footer pt-0">
      <template v-if="!ressourcesMovedTo365">
        <div>
          <span class="search__card__body__duration d-none" v-if="session.isLive"> ({{ session.durationMinutes }}mn) </span>
        </div>
        <b-row class="no-gutters">
          <b-col cols="9" xl order="1" order-xl="1" class="session__progress">
            <b-progress
              v-if="session.isLive"
              class="search__card__body__progress session__progress"
              :value="session.progress"
              :max="session.duration"
              height="0.6em"
            ></b-progress>
          </b-col>
          <b-col
            v-if="((session.isLive || session.isInInterlude) && session.channelId) || session.isReplay"
            cols="3"
            xl="session-btn"
            order="2"
            order-xl="2"
            class="text-right text-xl-right pt-3 pt-md-0 pb-1 pb-xl-0 pl-xl-3 position-static ml-auto"
          >
            <SessionButton :session="session" :forceInteract="session.isReplay" class="session__card__footer__action" />
          </b-col>
          <b-col v-else cols xl="session-btn" order="2" order-xl="2" class="text-right text-xl-right pt-3 pt-md-0 pb-1 pb-xl-0 pl-xl-3 position-static ml-auto">
            <router-link :to="`/${eventRoute}/sessions/${result.source.furl}`" class="session__card__footer__action card-link stretched-link">
              See details <i class="las la-4x la-t-plus-2 la-arrow-right"></i
            ></router-link>
          </b-col>
        </b-row>
      </template>
      <template v-else>
        <b-col cols xl="session-btn" order="2" order-xl="2" class="text-right text-xl-right pt-3 pt-md-0 pb-1 pb-xl-0 pl-xl-3 position-static ml-auto">
          <a v-if="result.source.dbId" :href="`${esc365SessionUrl(result.source.dbId)}`" class="session__card__footer__action card-link stretched-link">
            See details <i class="las la-4x la-t-plus-2 la-arrow-right"></i
          ></a>
        </b-col>
      </template>
    </div>
  </div>
</template>
<script>
  import { mapState } from 'vuex';
  import { HtmlHelper } from 'src/utils/html-helper';
  import FavoriteButton from 'src/components/Buttons/FavoriteButton';
  import SessionButton from 'src/components/Buttons/SessionButton';
  import esc365Mixin from 'src/mixins/esc365Mixin';
  import hybridEventMixin from 'src/mixins/hybridEventMixin';

  export default {
    name: 'SessionResultCard',
    mixins: [esc365Mixin, hybridEventMixin],
    components: {
      FavoriteButton,
      SessionButton,
    },
    props: {
      result: Object,
      serverTime: Number,
      query: String,
      searchDocType: String,
      docType: String,
    },
    data() {
      return {
        highlight: Object,
        presentationsSplit: '',
        speakers: '',
        role: '',
        fulltext: this.query,
      };
    },
    mounted() {
      // If needed...
      // this.updateRoles();
      // if (this.result.source.presentations) this.presentationsSplit = this.result?.source?.presentations[0]?.split(',')[0];
    },

    /*
  watch: {
    result() {
      if (this.result.source.presentations) this.presentationsSplit = this.result?.source?.presentations[0]?.split(',')[0];
    },
    query() {
      this.fulltext = this.query;
      this.updateRoles();
    },
  },
  */
    computed: {
      ...mapState({
        eventRoute: (state) => state.app.eventRoute,
        ressourcesMovedTo365: (state) => state.app.ressourcesMovedTo365,
      }),

      session() {
        let sessionResult = {};
        sessionResult.startTime = this.result.source.startTimeUnixEpoc;
        sessionResult.endTime = this.result.source.endTimeUnixEpoc;
        sessionResult.id = this.result.source.digitalSessionId;
        sessionResult.externalLink = null;
        sessionResult.rooms =
          this.result.source.roomDisplayName || this.result.source.channel ? this.result.source.roomDisplayName + ' ' + this.result.source.channel : 'On demand';
        sessionResult.hasExternalLink = this.result.source.externalLink && this.result.source.externalLink !== '';
        sessionResult.platformFullVideoId = this.result.source.replayVideoId;
        sessionResult.isInInterlude = false; //this.isInInterlude;
        sessionResult.isLive = sessionResult?.startTime <= this.serverTime && sessionResult?.endTime > this.serverTime;
        sessionResult.isReplay =
          sessionResult?.startTime < this.serverTime &&
          sessionResult?.endTime < this.serverTime &&
          sessionResult?.platformFullVideoId &&
          sessionResult?.platformFullVideoId !== undefined &&
          sessionResult?.platformFullVideoId !== null &&
          sessionResult?.platformFullVideoId !== '';
        sessionResult.duration = sessionResult?.endTime - sessionResult?.startTime;
        sessionResult.durationMinutes = sessionResult?.duration / (60 * 1000);
        sessionResult.progress = this.serverTime - sessionResult?.startTime;
        sessionResult.scientificSessionId = this.result.source.dbId; //this.result.data.scientific_session_id?.raw;
        sessionResult.channelId = this.result.source.channelId;
        //console.log(`externalLink: ${sessionResult.externalLink}`);
        //console.log(`hasExternalLink: ${sessionResult.hasExternalLink}`);
        //console.log(`channelid: ${sessionResult.channelId}`);
        //console.log('startTime ' + DateHelper.browserDateTime(result.startTime));
        //console.log('endTime ' + DateHelper.browserDateTime(result.endTime));
        return sessionResult;
      },
    },
    methods: {
      /*
    updateRoles() {
      if (this.fulltext) {
        this.fulltext = this.fulltext.toLowerCase();
        this.speakers = this.result.source.contributors?.find((x) => x.fullname.toLowerCase().includes(this.fulltext));

        this.role = this.speakers ? this.speakers.role : 'Speakers';
        return this.role;
      } else {
        return '';
      }
    },
    */
      extractTopicFromSnippet(rawTopics) {
        let rawTopicsList = rawTopics;
        return rawTopicsList;
      },
      showRoom(room, channel) {
        let rst = 'On Demand';
        if (room || channel) {
          rst = room ? room : '';
        }

        return rst;
      },
      showChannelRoom(room, channel) {
        let rst = '';
        if (channel) {
          channel = channel ? 'Online' : '';
          rst = channel;
        }

        return rst;
      },

      formatContentSnippet(rawContentSnippet) {
        return HtmlHelper.decodeHTMLEntities(rawContentSnippet);
      },
      isSponsoredSession(rawResult) {
        //return false;
        return (
          rawResult &&
          rawResult?.data &&
          rawResult?.data?.sub_title &&
          rawResult?.data?.sub_title?.raw &&
          (rawResult?.data.sub_title?.raw?.toLowerCase()?.includes('sponsored') || rawResult?.data.sub_title?.raw?.toLowerCase()?.includes('supported'))
        );
      },
      navigateToSession(event) {
        if (event.target.closest('.session__favorite__button, .search__card__footer__action')) {
          return;
        }
        this.$router.push(`/${this.eventRoute}${this.result.source.digitalUrl}`);
      },

      isSponsoredSessionType(session) {
        if (session.processType == 'Industry' && this.docType != 'DigitalSessionIndustry') {
          console.log('isSponsoredSessionType true');
          return true;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '~@/styles/theme';
  @import 'node_modules/bootstrap/scss/_functions';
  @import 'node_modules/bootstrap/scss/_variables';
  @import 'node_modules/bootstrap/scss/mixins/_breakpoints';

  .session__link {
    text-decoration: none;
    color: inherit;
    display: inline-block;
    width: 100%;
  }

  .session__progress {
    margin: auto;
  }

  .card-header {
    display: block;
  }

  .search__card {
    display: flex;
    flex-direction: column;
    height: 100%;
    border-radius: 6px;
    background: white;
    border: solid 1px $grey-lighter;
  }

  .search__card__header {
    padding: 12px 18px 1.2rem 18px;
  }

  .search__card__header__date {
    font-size: 0.8rem;
    color: $grey;
  }

  .search__card__header__button {
    display: inline-block;
    cursor: pointer;
    position: absolute;
    right: 7px;
    top: 0px;
    padding: 7px;
    border-left: solid 1px $grey-lighter;
    border-bottom: solid 1px $grey-lighter;
    border-radius: 0 6px;
  }

  .session__time {
    font-size: 0.9rem;
    color: #ae1022;
  }

  .card.session__card {
    border-radius: 2rem;
    border: 0;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    position: relative;
    bottom: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
  }

  .session__card .session__favorite__button {
    margin-left: auto;
    cursor: pointer;
    z-index: 100;
  }

  .session__card .material-icons-outlined,
  .session__card .material-icons-round {
    font-size: 16px;
  }

  .card-footer.search__card__footer {
    margin-top: auto;
    padding: 1rem 1.25rem;
    width: 100%;
    text-align: right;
  }

  .search__card__footer__action {
    color: var(--congress);
    font-size: 0.9rem;

    &:hover,
    :focus {
      text-decoration: none;
    }
  }

  .search__card__body__snippets {
    font-size: 0.8em;
    font-style: italic;
  }

  .search__card__body__subtitle {
    font-size: 0.8em;
  }

  .search__card__body__duration {
    font-size: 0.85em;
    color: $grey;
  }

  .session__card__header_live-now {
    margin-left: 40px;
    color: $secondary;
    background-color: var(--congress);
    font-size: 0.6em;
    text-transform: uppercase;
    text-align: center;
    padding: 2px 8px;
    border-radius: 8px;
  }

  .search__card__body__snippets {
    font-size: 0.8em;
  }

  ::v-deep em {
    font-style: italic !important;
  }
  ::v-deep blockquote em {
    font-style: italic;

    font-weight: bold;
  }

  /*ONBOARDING TOUR*/
  .search__card__header__button.v-tour__target--highlighted {
    border-radius: 0.5rem;
  }

  .v-step__header,
  .v-step,
  .v-step__arrow--dark {
    background: #000;
  }




  /* sponsored sessions reveal on hover */
  .session-item .sponsored-session {
    background-color: transparent;
  }

  .session-item .sponsored-session .sponsored-back {
    /*position: absolute;*/
    display: none;
    opacity: 0;
    /* animation */
    /*
    transform: scale(0);
    transition: all 1s ease-in-out;
    */
  }

  .session-item:hover .sponsored-session .sponsored-back,
  .session-item:focus .sponsored-session .sponsored-back {
    display: block;
    opacity: 1;
    /* animation */
    /*
    transform: scale(1);
    transition: all 0.25s ease-in-out;
    */
  }

  .session-item .sponsored-session .sponsored-front {
    /*position: absolute;*/
    display: block;
    opacity: 1;
    /* animation */
    /*
    transform: scale(1);
    transition: all 1s ease-in-out;
    */
  }

  .session-item:hover .sponsored-session .sponsored-front,
  .session-item:focus .sponsored-session .sponsored-front {
    display: none;
    opacity: 0;
    /* animation */
    /*
  transform: scale(0);
  transition: all 0.25s ease-in-out;
  */
  }
</style>
