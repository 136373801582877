<template>
  <fragment v-if="!isLoading">
    <IndustryToastManager v-if="enableNotifications" />
    <NewVersionToast v-if="enableNotifications" id="new-version-toast" />

    <div v-if="showHeader" class="sticky-top">
      <Navbar />
    </div>
    <!-- <div v-if="!isMobileAppPage && !isHomePage" class="timeInfo">Please note that the programme times are displayed on local times in {{ locationEvent }}.</div> -->
    <b-container
      fluid
      class="main-container px-0"
      :class="{
        'pr-0': isLiveMode && !isPageWithoutTimeline,
        'pr-0': !isLiveMode && !isPageWithoutTimeline,
        'pr-3': isPageWithoutTimeline,
        'pl-0': !isPageWithoutTimeline,
        'pl-3': isPageWithoutTimeline,
      }"
    >
      <b-row
        no-gutters
        :align-h="!preCongressMode && !postCongressMode && !isPageWithoutTimeline ? 'center' : 'center'"
        :class="{ 'm-0': preCongressMode || postCongressMode }"
      >
        <b-col v-if="!isPageWithoutTimeline && !preCongressMode && !postCongressMode" cols="12" class="d-none">
          <div class="timeline-button" :data-expanded="timelineExpandedData" @click="toggleTimeLine">
            <i class="las la-2x la-t-plus-6 la-calendar-alt"></i>
          </div>
        </b-col>

        <!-- RouterView -->
        <router-view :key="generateRouterKey()" v-if="isMounted" />
      </b-row>
    </b-container>

    <NetworkingChat v-if="isLogged && hasNetworkingAccess && !isNetworkingMessagePage && isNetworkingEnabled" :hidden="true" />

    <ChannelModal />
    <MediaModal />

    <Footer v-if="showFooter" />
  </fragment>
</template>

<script>
  import cssVars from 'css-vars-ponyfill';
  import { mapGetters, mapState } from 'vuex';

  import { SfChatBot } from 'src/assets/scripts/sf-chatbot';
  import { getDefaultRoute } from 'src/router/default';
  import { MediaApiService } from 'src/services/media-api';
  import { getStoryBlokClient, getStoryBlokPreviewToken, isInStoryBlokEditorPreview } from 'src/services/storyblok';
  import { FETCH_BANNERS, FETCH_FAVS, TOGGLE_TIMELINE } from 'src/store/actions';
  import { SET_CHAT_PARTICIPANT_CONFIG, SET_CMS_CACHE_VERSION, SET_CONF_CMS_DATA, SET_HOME_CMS_DATA, SET_IS_LOADING, SET_USER_INFO } from 'src/store/mutations';

  import ChannelModal from 'src/components/Modals/ChannelModal';
  import MediaModal from 'src/components/Modals/MediaModal';
  import NetworkingChat from 'src/components/NetworkingChat';
  import IndustryToastManager from 'src/components/Toasts/IndustryToastManager';
  import NewVersionToast from 'src/components/Toasts/NewVersionToast';
  import { constants } from 'src/constants';
  import hybridEventMixin from 'src/mixins/hybridEventMixin';
  import { consoleColors } from 'src/utils/console-color';
  import { DateHelper } from 'src/utils/date-helper';
  import Footer from './Footer/Footer';
  import Navbar from './Navbar/Navbar';

  export default {
    name: 'EventWrapper',
    mixins: [hybridEventMixin],
    props: {
      showHeader: {
        type: Boolean,
        default: true,
      },
      showFooter: {
        type: Boolean,
        default: true,
      },
      enableNotifications: {
        type: Boolean,
        default: true,
      },
    },
    components: {
      Navbar,
      Footer,
      ChannelModal,
      MediaModal,
      NewVersionToast,
      IndustryToastManager,
      NetworkingChat,
    },
    data() {
      return {
        isMounted: false,
        chatBot: null,
        inView: true,
      };
    },
    computed: {
      // Timeline status data attribute
      timelineExpandedData() {
        return this.isTimelineExpanded ? 'expanded' : 'collapsed';
      },
      isLiveMode() {
        return !this.preCongressMode && !this.postCongressMode;
      },
      ...mapGetters(['appVersion', 'isLogged', 'currentUser', 'isMediaModalOpen', 'isTimelineExpanded']),
      ...mapState({
        appVersion: (state) => state.app.version,
        isLoading: (state) => state.app.isLoading,
        eventId: (state) => state.app.eventId,
        eventBaseTitle: (state) => state.app.eventBaseTitle,
        preCongressMode: (state) => state.app.preCongressMode,
        postCongressMode: (state) => state.app.postCongressMode,
        ressourcesMovedTo365: (state) => state.app.ressourcesMovedTo365,
        eventRoute: (state) => state.app.eventRoute,
        cmsCacheVersion: (state) => state.app.cmsCacheVersion,
        chatParticipantConfig: (state) => state.app.chatParticipantConfig,
        userInfo: (state) => state.app.userInfo,
      }),
      /*
      isSchedulePage() {
        return this.$route.path === `/${this.eventRoute}/schedule` || this.$route.path === `/${this.eventRoute}/schedule-poc`;
      },
      */
      isMobileAppPage() {
        return this.$route.path.startsWith(`/${this.eventRoute}/${constants.ROUTE_MOBILE_APP}`);
      },
      isNetworkingMessagePage() {
        return (
          this.chatParticipantConfig && this.chatParticipantConfig.route && this.$route.path === `/${this.eventRoute}/${this.chatParticipantConfig.route}/messages`
        );
        //this.$route.path === `/${this.eventRoute}/${constants.ROUTE_NETWORKING_MESSAGES}`;
      },
      isNetworkingEnabled() {
        return this.chatParticipantConfig && this.chatParticipantConfig.eventId && this.chatParticipantConfig.eventId == this.eventId;
      },
      hasNetworkingAccess() {
        return this.chatParticipantConfig && this.chatParticipantConfig.route && this.chatParticipantConfig.networkingChatAgreement;
      },
      isHomePage() {
        return this.$route.path === `/${this.eventRoute}` || this.$route.path === `/${this.eventRoute}/` || this.$route.path === `/${this.eventRoute}/home`;
      },
      isPageWithoutTimeline() {
        const pagesWithoutTimeline = [
          constants.ROUTE_SCHEDULE,
          constants.ROUTE_NETWORKING_SEARCH,
          constants.ROUTE_NETWORKING_MESSAGES,
          constants.ROUTE_NETWORKING_HOT_DISCUSSIONS,
          `${constants.ROUTE_MOBILE_APP}/${constants.ROUTE_MOBILE_APP_PRE_HOME}`,
          `${constants.ROUTE_MOBILE_APP}/${constants.ROUTE_MOBILE_APP_HAPPENING_NOW}`,
          `${constants.ROUTE_MOBILE_APP}/${constants.ROUTE_MOBILE_APP_INTERACTIVITY}`,
        ];
        return pagesWithoutTimeline.map((x) => `/${this.eventRoute}/${x}`).includes(this.$route.path);
        /*
        let isPageWithoutTimeline = false;
        for (let page of pagesWithoutTimeline) {
          if (this.$route.path === `/${this.eventRoute}/${page}`) {
            isPageWithoutTimeline = true;
            break;
          }
        }
        return isPageWithoutTimeline;
        */
      },
      isChatbotEnabled() {
        return this.eventId == 946 && !this.preCongressMode && !this.postCongressMode;
      },
      smartBannerMeta() {
        require('smartbanner.js/dist/smartbanner.min.css');
        require('smartbanner.js/dist/smartbanner.min.js');
        if (process.env.VUE_APP_ENABLE_SMART_BANNER === 'true' && !this.isMobileAppPage) {
          switch (this.eventId) {            
            case constants.EE_2024_EVENT_ID:
            return constants.EE_2024_SMART_BANNER_META;
            /*
            case constants.ESC_2024_EVENT_ID:
              return constants.ESC_2024_SMART_BANNER_META;
            case constants.PREV_2024_EVENT_ID:
              return constants.PREV_2024_SMART_BANNER_META;
            case constants.HF_2024_EVENT_ID:
              return constants.HF2024_SMART_BANNER_META;
            case constants.EHRA_2024_EVENT_ID:
              return constants.EHRA_2024_SMART_BANNER_META;
            case constants.ACVC_2024_EVENT_ID:
              return constants.ACVC_2024_SMART_BANNER_META;
            case constants.ESC_2023_EVENT_ID:
              return constants.ESC_2023_SMART_BANNER_META;
            case constants.ACNAP_2023_EVENT_ID:
              return constants.ACNAP_2023_SMART_BANNER_META;
            case constants.HF_2022_EVENT_ID:
              return constants.HF_2022_SMART_BANNER_META;
            case constants.ACNAP_2022_EVENT_ID:
              return constants.ACNAP_2022_SMART_BANNER_META;
            case constants.ESC_2022_EVENT_ID:
              return constants.ESC_2022_SMART_BANNER_META;
            case constants.ACVC_2023_EVENT_ID:
              return constants.ACVC2023_SMART_BANNER_META;
            case constants.EAPC_2023_EVENT_ID:
              return constants.EAPC2023_SMART_BANNER_META;
            case constants.EHRA_2023_EVENT_ID:
              return constants.EHRA2023_SMART_BANNER_META;
            case constants.EACVI_2023_EVENT_ID:
              return constants.EACVI2023_SMART_BANNER_META;
            */
            default:
              return constants.NO_BANNER_SMART_BANNER_META;
          }
        } else {
          console.log('Smart banner is disabled');
          return constants.NO_BANNER_SMART_BANNER_META;
        }
      },
      browserTimeZone() {
        return DateHelper.browserTimeZone();
      },
    },
    watch: {
      $route(to) {
        if (window && window.__sharethis__) {
          window.__sharethis__.href = `${window.location.host}${to.fullPath}`;
        }
        //console.log(`Route changed from ${from.path} to ${to.path}`);
        //console.log(`EventWrapper - this.isTimelineExpanded ${this.isTimelineExpanded}`)
        //console.log(`EventWrapper - $route.query.isTimelineExpanded ${this.$route.query.isTimelineExpanded}`)
        //console.log(`EventWrapper - this.$route.query.isTimelineExpanded == true ${this.$route.query.isTimelineExpanded == 'true'}`)
        //if (from.path !== to.path && this.isTimelineExpanded && this.$route.query.isTimelineExpanded != 'true') this.toggleTimeLine();
        //else if (!this.isTimelineExpanded && this.$route.query.isTimelineExpanded == 'true') this.toggleTimeLine();
      },
    },

    metaInfo() {
      return { meta: this.smartBannerMeta };
    },
    async mounted() {
      if (this.isLogged) {
        MediaApiService.setHeader();

        //DataLayer
        this.$gtm.push({ event: 'logEscId', escid: this.currentUser.escId });

        if (this.$appInsights) {
          const logEscIdProps = { escId: this.currentUser.escId };
          console.info(`%cAppInsights: logEscId ${JSON.stringify(logEscIdProps)}`, consoleColors.insights);
          this.$appInsights?.trackEvent({ name: 'logEscId', properties: logEscIdProps });
        }
      }

      await this.loadEventConfiguration();

      try {
        await this.$loadScript(`https://app.storyblok.com/f/storyblok-v2-latest.js?t=${getStoryBlokPreviewToken()}`);
        await this.loadEventCmsConfiguration();
      } catch (err) {
        console.error('A fatal error has occurred regarding Storyblok', err);
      }

      if (!this.isMobileAppPage) {
        try {
          await this.$loadScript(
            'https://platform-api.sharethis.com/js/sharethis.js#property=64a2c986f57abe0019c4e0f6&product=sticky-share-buttons&source=platform'
          );
        } catch (err) {
          console.error('A fatal error has occurred regarding sharethis', err);
        }
      }

      await this.loadUserChatParticipantConfiguration();
      if (this.isChatbotEnabled) {
        this.chatBot = new SfChatBot(this.currentUser);
        this.chatBot.load();
      }

      this.isMounted = true;

      if (this.isLogged) {
        // Fetching favorites...
        this.$store.dispatch(FETCH_FAVS, this.eventId);
      }

      //console.log(`EventWrapper - this.isTimelineExpanded & ${this.isTimelineExpanded}`)
      //console.log(`EventWrapper - $route.query.isTimelineExpanded ${this.$route.query.isTimelineExpanded}`)
      //if (this.isTimelineExpanded && this.$route.query.isTimelineExpanded != 1)  this.toggleTimeLine();
      //else if (!this.isTimelineExpanded && this.$route.query.isTimelineExpanded == 1) this.toggleTimeLine();

      await this.loadUserInfo();
    },
    methods: {
      generateRouterKey() {
        return this.preCongressMode.toString().concat(this.postCongressMode.toString());
      },
      async loadUserChatParticipantConfiguration() {
        /*
      let data = await MediaApiService.getUserParticipantChatConfig();
      //console.log(`chatParticipantconfig ${JSON.stringify(data)}`);
      // Dispatching chatParticipantconfig in store...
      this.$store.commit(SET_CHAT_PARTICIPANT_CONFIG, data);
      */
        await MediaApiService.getUserParticipantChatConfig(this.eventId).then(({ data }) => {
          if (data) {
            //console.log(`chatParticipantconfig data ${JSON.stringify(data)}`);
            this.$store.commit(SET_CHAT_PARTICIPANT_CONFIG, data);
            //console.log(`chatParticipantconfig ${JSON.stringify(this.chatParticipantConfig)}`);
          }
        });
      },

      async loadUserInfo() {
        //console.log(this.eventId);
        await MediaApiService.GetUserInfo(this.eventId).then(({ data }) => {
          if (data) {
            this.$store.commit(SET_USER_INFO, data);
          }
        });
      },

      async loadEventCmsConfiguration() {
        const eventRouteToLower = this.eventRoute.toLowerCase();

        const storyblokClient = getStoryBlokClient();

        //console.log(`cmsCacheVersion in config ${this.cmsCacheVersion}`);
        // retrieving storyblok config story...
        if (!this.cmsCacheVersion) {
          const { data: sbConfigData } = await storyblokClient.get(`cdn/spaces/me`);

          const sbVersion = sbConfigData?.space?.version ?? 0;
          this.$store.commit(SET_CMS_CACHE_VERSION, sbVersion);
        }

        const isInEditorPreview = isInStoryBlokEditorPreview();
        //console.log(`EventWrapper - isInEditorPreview ${isInEditorPreview}`);

        // retrieving event config story...
        const { data: configData } = await storyblokClient.get(`cdn/stories/${eventRouteToLower}/config`, {
          cv: !isInEditorPreview ? this.cmsCacheVersion : undefined,
          version: !isInEditorPreview ? 'published' : 'draft',
        });
        const configStoryData = configData.story.content;
        this.$store.commit(SET_CONF_CMS_DATA, configStoryData);

        // retrieving event home story...
        const { data: homeData } = await storyblokClient.get(`cdn/stories/${eventRouteToLower}`, {
          cv: !isInEditorPreview ? this.cmsCacheVersion : undefined,
          version: !isInEditorPreview ? 'published' : 'draft',
        });
        const homeStoryData = homeData.story.content;
        this.$store.commit(SET_HOME_CMS_DATA, homeStoryData);
      },
      async loadEventConfiguration() {
        // DataLayer
        this.$gtm.push({ eventId: this.eventId });

        // Setting document title...
        document.title = this.eventBaseTitle;

        // Injecting congress theme...
        this.injectCongressTheme();

        setTimeout(() => {
          this.$store.commit(SET_IS_LOADING, false);
        }, 500);

        // Refreshing banners...
        // this.refreshBanners();
      },
      injectCongressTheme() {
        const head = document.head;
        const origin = window.location.origin;

        const congressThemeLink = origin.concat(`${process.env.BASE_URL}css/events/${this.eventId}/theme.css?v=${this.appVersion}`);
        let congressTheme = document.querySelector(`link[href~='${congressThemeLink}']`);
        if (!congressTheme) {
          congressTheme = document.createElement('link');
          head.appendChild(congressTheme);
          congressTheme.rel = 'stylesheet';
          congressTheme.type = 'text/css';
          congressTheme.onload = () => {
            setTimeout(() => cssVars());
          };
          congressTheme.href = congressThemeLink;
        }

        let congressFavicon = document.querySelector(`link[rel~='icon']`);
        if (!congressFavicon) {
          congressFavicon = document.createElement('link');
          congressFavicon.rel = 'icon';
          head.appendChild(congressFavicon);
        }

        congressFavicon.href = origin.concat(`${process.env.BASE_URL}img/events/${this.eventId}/favicon.png?v=${this.appVersion}`);
      },
      refreshBanners() {
        this.$store.dispatch(FETCH_BANNERS);
      },
      toggleTimeLine() {
        this.$store.dispatch(TOGGLE_TIMELINE);
      },
      async redirectToDefaultRoute() {
        const defaultRoute = getDefaultRoute();
        await this.$router.replace(defaultRoute);
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '~@/styles/theme';
  @import 'node_modules/bootstrap/scss/_functions';
  @import 'node_modules/bootstrap/scss/_variables';
  @import 'node_modules/bootstrap/scss/mixins/_breakpoints';

  .main-container {
    position: relative;
    min-height: 70vh;
    //padding-bottom: $footer-height; // Padding bottom by footer height
  }

  @include media-breakpoint-down(md) {
    .main-container {
      padding-bottom: $footer-height-md; // Padding bottom by footer height
    }
  }

  .spinner-grow {
    width: 4rem;
    height: 4rem;
  }

  .timeInfo {
    padding-bottom: 10px;
    padding-left: 15px;
    font-size: 0.9em;
  }
  @media (max-width: 992px) {
    .sticky-top {
      top: -53px;
    }
  }
    @include media-breakpoint-down(sm) {
    .sticky-top {
      top: -70px;
    }
  }
  @media (min-width: 992px) {
    .sticky-top {
      top: -70px;
    }

    .sticky-top:has(.subnav__collapsed) {
      height: 180px;
      backdrop-filter: blur(16px);
    }

    .sticky-top:has(.subnav__collapsed) + .timeLine {
      color: blue !important;
    }
  }

  /*v-tour style */
  ::v-deep .v-step__header,
  ::v-deep .v-step,
  ::v-deep .v-step__arrow--dark:before {
    background-color: #000 !important;
  }

  ::v-deep .v-step {
    border-radius: 1rem !important;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6) !important;
  }

  ::v-deep .v-step__header {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }

  ::v-deep .v-step__button {
    padding: 0.335rem 1rem !important;
    border: 2px solid #fff !important;
    border-radius: 50px !important;
    height: auto !important;
  }

  ::v-deep .v-tour__target--highlighted {
    z-index: 3 !important;
    border-radius: 50px !important;
    @media (max-width: 768px) {
      z-index: 10000 !important;
    }
  }
</style>
