<template>
  <div v-if="!isLoading && registrationStatus">
    <b-container fluid v-if="!registrationStatus.logged">
      <b-row class="content-wrapper vh-100 text-center" align-v="center">
        <b-col>
          <b-jumbotron header="Protected content" lead="This content is protected, please sign in.">
            <b-button pill variant="mobileapp" @click="startLoading" :href="loginUrl" class="with-icon">
              <i class="las la-5x la-t-plus-3 la-user-circle pr-1"></i>
              Sign in
            </b-button>
          </b-jumbotron>
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid v-else-if="!registrationStatus.registered">
      <b-row class="content-wrapper vh-100 text-center" align-v="center">
        <b-col>
          <b-jumbotron header="I have not registered yet" lead=" ">
            <div class="my-2">
              <b-button variant="mobileapp" @click="startLoading" :href="registerUrl">
                <i class="las la-5x la-t-plus-3 la-user-circle pr-1"></i>
                I want to register
              </b-button>
            </div>
            <div v-if="false" class="my-2">
              <b-button variant="mobileapp" @click="startLoading" :href="accessCodeUrl">
                <i class="las la-5x la-t-plus-3 la-user-circle pr-1"></i>
                I have an access code
              </b-button>
            </div>
          </b-jumbotron>
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid v-else-if="registrationStatus.registered">
      <b-row class="content-wrapper vh-100 text-center" align-v="center">
        <b-col>
          <b-jumbotron header="I've already registered" lead="Enjoy your congress"> </b-jumbotron>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import { SET_IS_LOADING } from 'src/store/mutations';
  //import { redirectErrorNotFound } from 'src/services/auth';
  import { MediaApiService } from 'src/services/media-api';
  import { QueryHelper } from 'src/utils/query-helper';
  import Cookies from 'js-cookie';

  export default {
    name: 'PartnerRegistrationStatus',
    components: {},
    data() {
      return {
        registrationStatus: null,
      };
    },
    computed: {
      ...mapState({
        isLoading: (state) => state.app.isLoading,
      }),
      loginUrl() {
        return `https://sp.shapper.net/simplesaml/module.php/core/authenticate.php?acr_values=null&response_type=code&client_id=escsaml&redirect_uri=https://secure11.shapper.net/services/request/authOpenId?appId=${this.registrationStatus?.appId}&scope=urn:oasis:names:tc:SAML:1.1:nameid-format:emailAddress&as=escardio&appId=${this.registrationStatus?.appId}`;
      },
      registerUrl() {
        return this.registrationStatus?.registrationLink;
      },
      accessCodeUrl() {
        return 'https://myesc.escardio.org/s/group-registration?eventid=a237T000002bc3mQAA';
      },
    },
    created() {
      /*
      //Rotate  left
      document.documentElement.style.setProperty('transform', 'rotate(-90deg)');
      document.documentElement.style.setProperty('transform-origin', 'top left');
      document.documentElement.style.setProperty('top', '100vh');
      */
      //Rotate  right
      /*
      document.documentElement.style.setProperty('transform', 'rotate(90deg)');
      document.documentElement.style.setProperty('transform-origin', 'top left');
      document.documentElement.style.setProperty('left', '100vw');
      */
    },
    beforeDestroy() {
      /*
      document.documentElement.style.removeProperty('transform');
      document.documentElement.style.removeProperty('transform-origin');
      document.documentElement.style.removeProperty('left');
      document.documentElement.style.removeProperty('top');
      */
    },
    mounted() {
      let queryString = window.location.search;
      const eescid = Cookies.get('eescid');
      queryString = QueryHelper.addUrlParameter(queryString, 'escid', eescid);
      if (queryString) {
        MediaApiService.partnerGetRegistrationStatus(queryString).then(({ data }) => {
          if (data) {
            //console.log(data);
            this.pushAnalytics(data);
            this.registrationStatus = data;
            //console.log(data);
          }
          this.stopLoading();
        });
      } else {
        this.stopLoading();
        //redirectErrorNotFound();
      }
    },
    methods: {
      startLoading() {
        console.log('startLoading');
        this.$store.commit(SET_IS_LOADING, true);
      },
      stopLoading() {
        this.$store.commit(SET_IS_LOADING, false);
      },
      pushAnalytics(data) {
        //console.log('data ' + JSON.stringify(data));
        if (data && data?.hasAccess && data?.escId && data?.eventId) {
          //console.log('GTM PUSH');
          /* DataLayer */
          this.$gtm.push({ eventId: data.eventId });
          this.$gtm.push({ event: 'logEscId', escid: data.escId });
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  @import '~@/styles/theme';
  @import 'node_modules/bootstrap/scss/_functions';
  @import 'node_modules/bootstrap/scss/_variables';
  @import 'node_modules/bootstrap/scss/mixins/_breakpoints';

  .player-widget {
    height: 100svh;
    width: 100svw;
  }

  .content-wrapper {
    background: #656565;
    background-blend-mode: multiply, multiply;
  }

  .display-3 {
    font-size: 2rem;
  }

  .lead {
    font-size: 1rem;
  }

  .btn-mobileapp {
    background-color: #ae1022;
    //var(--congress);
    color: #fff;
  }

  .btn-mobileapp:hover {
    color: #fbdfd7;
    //var(--congress-light);
  }
</style>
