import { render, staticRenderFns } from "./VenueOverview.vue?vue&type=template&id=2977c15b&scoped=true"
import script from "./VenueOverview.vue?vue&type=script&lang=js"
export * from "./VenueOverview.vue?vue&type=script&lang=js"
import style0 from "./VenueOverview.vue?vue&type=style&index=0&id=2977c15b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2977c15b",
  null
  
)

export default component.exports