<template>
  <div id="appcontainer" class="position-relative">
    <b-overlay :show="isLoading" :opacity="1" spinner-type="grow" :style="{ height: isLoading ? '100vh' : 'auto' }">
      <!-- RouterView -->
      <router-view />
    </b-overlay>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { DISABLE_POOLING, ENABLE_POOLING } from 'src/store/actions';
import { consoleColors } from 'src/utils/console-color';
import { refreshPageMixin } from 'src/mixins/refreshPageMixin';
//import { MediaApiService } from 'src/services/media-api';
//import { SfChatBot } from 'src/assets/scripts/sf-chatbot';

export default {
  name: 'App',
  mixins: [refreshPageMixin],
  data() {
    return {
      inView: true,
    };
  },
  computed: {
    ...mapGetters(['isLogged', 'currentUser', 'isMediaModalOpen', 'isChannelModalOpen']),
    ...mapState({
      isLoading: (state) => state.app.isLoading,
    }),
  },
  watch: {
    inView(inView) {
      console.info(`%cFocus changed: ${JSON.stringify(inView)}`, consoleColors.events);
      //console.log(`isMediaModalOpen ${this.isMediaModalOpen}`);
      //console.log(`isChannelModalOpen ${this.isChannelModalOpen}`);
      if (!this.isMediaModalOpen && !this.isChannelModalOpen) {
        if (!inView) {
          this.$store.dispatch(DISABLE_POOLING); // Disabling pooling...
          //console.info(`%cFocus out - Pooling disabled`, consoleColors.events);
        } else {
          this.$store.dispatch(ENABLE_POOLING); // Enabling pooling...
          //console.info(`%cFocus in - Pooling enabled`, consoleColors.events);
        }
      } else {
        console.info(`%cModal opened - Pooling suspended`, consoleColors.events);
      }
    },
    newVersion(newVersion) {
      if (newVersion) this.$bvToast.show('new-version-toast');
    },
  },
  async mounted() {
    if (this.$appInsights) {
      const logAppInfosProps = { version: this.appVersion };
      console.info(`%cAppInsights: logAppInfos ${JSON.stringify(logAppInfosProps)}`, consoleColors.insights);
      this.$appInsights?.trackEvent({ name: 'logAppInfos', properties: logAppInfosProps });
    }

    // Init version check...
    const checkVersionInterval = parseInt(process.env.VUE_APP_CHECK_VERSION_INTERVAL);
    this.initVersionCheck(checkVersionInterval); // If you want to test set: 2000

    // Listening for update requests...
    this.$root.$on('updateRequested', () => {
      this.reloadApp();
    });

/*
    if (this.isLogged) {
      MediaApiService.setHeader();

      //DataLayer
      this.$gtm.push({ event: 'logEscId', escid: this.currentUser.escId });

      if (this.$appInsights) {
        const logEscIdProps = { escId: this.currentUser.escId };
        console.info(`%cAppInsights: logEscId ${JSON.stringify(logEscIdProps)}`, consoleColors.insights);
        this.$appInsights?.trackEvent({ name: 'logEscId', properties: logEscIdProps });
      }
    }
*/

    //this.chatBot = new SfChatBot(this.currentUser);
    //this.chatBot.load();

    // Watching if user focus is getting out...
    this.detectFocusOut();
  },
  methods: {
    detectFocusOut() {
      //console.log('detectFocusOut triggered');
      const _this = this;

      const onWindowFocusChange = (e) => {
        //console.log('onWindowFocusChange triggered');
        if ({ focus: 1, pageshow: 1 }[e.type]) {
          //console.log('onWindowFocusChange - FOCUS IN');
          if (_this.inView) return;
          this.tabFocus = true;
          _this.inView = true;
        } else if (_this.inView) {
          //console.log('onWindowFocusChange - FOCUS OUT');
          this.tabFocus = !this.tabFocus;
          _this.inView = false;
        }
      };

      window.addEventListener('focus', onWindowFocusChange);
      window.addEventListener('blur', onWindowFocusChange);
      window.addEventListener('pageshow', onWindowFocusChange);
      window.addEventListener('pagehide', onWindowFocusChange);
    },
  },
};
</script>

<style lang="scss">
@import '~@/styles/theme';
@import 'node_modules/bootstrap/scss/bootstrap';
@import 'node_modules/bootstrap-vue/src/index';
@import '~@/styles/app';
</style>
