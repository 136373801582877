<template>
  <div class="nav-bars">
    <!--
    <div class="top-nav-bar">
      <div >
        <img src="~@/assets/images/platform/logo_esc.svg" alt="logo esc" class="navbar-logo--365" />
        <span>ESC 365</span>
      </div>
    </div>
    -->
    <b-navbar toggleable="xl" class="navbar-365" offset-md="2">
      <b-navbar-brand :href="esc365Url" class="p-0">
        <img src="~@/assets/images/platform/logo_esc.svg" alt="logo esc" />
        <span class="ml-1 mr-4">ESC 365</span>
        <span class="pl-4 border-left d-none" style="font-family: Interstate WGL Black">European Society of Cardiology</span>
      </b-navbar-brand>
      <!-- Right aligned nav items -->
      <b-navbar-nav class="mr-1">
        <b-nav-form>
          <!-- If NOT logged -->
          <template v-if="!isLogged">
            <b-dropdown text="ESC Platforms" variant="outline-secondary" class="mr-2 d-none d-md-block" size="sm">
              <b-dropdown-item href="https://www.escardio.org/" link-class="escplatforms" target="_blank" class="font-regular"> Escardio.org </b-dropdown-item>
              <b-dropdown-item href="https://esc365.escardio.org/" link-class="escplatforms" class="font-regular"> ESC 365 </b-dropdown-item>
              <b-dropdown-item href="https://escelearning.escardio.org/" link-class="escplatforms" target="_blank" class="font-regular">
                ESC eLearning
              </b-dropdown-item>
            </b-dropdown>
            <b-button pill size="sm" class="my-2 my-sm-0 mr-2 btn-outline-congress d-none d-sm-block" variant="outline-dark" :href="registerUrl" target="_blank">
              Create an account
            </b-button>
          </template>

          <span v-if="!isLogged">
            <b-button pill size="sm" variant="dark" class="my-2 my-sm-0 mr-2" @click="login" data-v-step-overview2="0">
              <span>Sign in</span>
            </b-button>
          </span>
          <span v-if="isLogged">
            <b-dropdown text="ESC Platforms" class="mr-2 rounded-pill d-none d-md-inline-flex" size="sm">
              <b-dropdown-item href="https://www.escardio.org/" link-class="escplatforms" target="_blank" class="font-regular"> Escardio.org </b-dropdown-item>
              <b-dropdown-item href="https://esc365.escardio.org/" link-class="escplatforms" class="font-regular"> ESC 365 </b-dropdown-item>
              <b-dropdown-item href="https://escelearning.escardio.org/" link-class="escplatforms" target="_blank" class="font-regular">
                ESC eLearning
              </b-dropdown-item>
            </b-dropdown>
            <b-button pill class="my-2 my-sm-0 mr-2 btn-membership" :href="becomeMemberUrl" target="_blank" size="sm">
              <span>Membership</span>
            </b-button>
          </span>

          <!-- If logged -->
          <template v-if="isLogged">
            <b-dropdown right size="sm" id="user__nav">
              <template #button-content>
                <span class="font-regular">{{ firstName }} {{ lastName }}</span>
              </template>
              <b-dropdown-item class="font-regular" :href="userProfileUrl">Profile</b-dropdown-item>
              <b-dropdown-item class="font-regular" :to="`/${eventRoute}/my-programme`">My Programme</b-dropdown-item>
              <b-dropdown-item class="font-regular" :href="myAccountUrl" target="_blank">My account</b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item class="font-regular" :href="sloUrl">Sign Out</b-dropdown-item>
            </b-dropdown>
          </template>
        </b-nav-form>
      </b-navbar-nav>
    </b-navbar>

    <b-navbar id="topStickyNav" toggleable="xl" type="light" class="navbar-full">
      <b-navbar-brand :to="`/${eventRoute}`">
        <img :src="`${publicPath}img/events/${eventId}/logo.png?v=${this.appVersion}`" class="navbar-logo" alt="Congress Logo" />
      </b-navbar-brand>

      <div class="d-inline-block mx-auto mx-sm-0 ml-auto pt-2">
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      </div>

      <b-collapse id="nav-collapse" is-nav>
        <!-- Quiz -->
        <TakeQuizzWidget />

        <b-navbar-nav class="d-flex">
          <template>
            <div v-for="navEntry in confCmsData.navbar_blocks" :key="navEntry._uid">
              <b-nav-item
                v-if="navEntry.isDisplayed"
                :to="navEntry.link_internal ? navEntry.link_value : null"
                :href="!navEntry.link_internal ? navEntry.link_value : null"
                :target="!navEntry.link_internal && navEntry.link_new_tab ? '_blank' : '_self'"
                class="m-auto nav_item"
                :style="navEntry.inline_css?navEntry.inline_css:''"
                @click="clickToCollapse(navEntry)"
              >
                <span v-if="navEntry.display_icon_live" class="live-icon"></span>
                <span>{{ navEntry.link_text }}</span>
                <b-button
                  v-if="navEntry.children && navEntry.children.length > 0"
                  :data-v-step="navEntry.tourIndex"
                  class="p-0 border-0"
                  style="height: 24px"
                  @click="handleToggleClick(navEntry.link_text, $event)"
                >
                  <span v-if="isCollapsedSection[navEntry.link_text] || isCollapsedSection[navEntry.link_text] == null" class="material-icons-round">
                    expand_more
                  </span>
                  <span v-if="!isCollapsedSection[navEntry.link_text] && isCollapsedSection[navEntry.link_text] != null" class="material-icons-round">
                    keyboard_arrow_up
                  </span>
                </b-button>
              </b-nav-item>
              <div
                class="subnav__collapsed mt-0 font-black collapse show"
                v-if="!isCollapsedSection[navEntry.link_text] && isCollapsedSection[navEntry.link_text] != null"
              >
                <b-container class="m-auto">
                  <b-list-group horizontal="md" class="justify-content-md-center m-auto">
                    <div v-for="subNavEntry in navEntry.children" :key="subNavEntry._uid">
                    <b-list-group-item
                      v-if="subNavEntry.isDisplayed"
                      :to="subNavEntry.link_internal ? subNavEntry.link_value : null"
                      :href="!subNavEntry.link_internal ? subNavEntry.link_value : null"
                      :target="!subNavEntry.link_internal && subNavEntry.link_new_tab ? '_blank' : '_self'"
                      class="m-auto"
                      :style="subNavEntry.inline_css?subNavEntry.inline_css:''"
                      ><span v-if="subNavEntry.isDisplayed">{{ subNavEntry.link_text }}</span></b-list-group-item
                    >
                  </div>
                  </b-list-group>
                </b-container>
              </div>
            </div>
          </template>
        </b-navbar-nav>

        <!-- RIGHT NAV -->

        <b-navbar-nav class="ml-auto rightNav">
          <b-nav-item v-if="(!isLogged || !hasNetworkingAccess) && isNetworkingEnabled && !preCongressMode" class="nav-networking" disabled>
            <b-button variant="link" disabled>
              <img src="~@/assets/images/platform/networking-icon-disabled.svg" alt="networking" class="mb-1" />
              <b>NETWORKING</b>
            </b-button>
          </b-nav-item>

          <CrossLinkWidget />

          <b-nav-item v-if="false && !isLogged" @click="login" class="nav-login">
            <b-button variant="link">
              <i class="las la-10x la-t-plus-3 la-user-circle"></i>
              <b>SIGN IN</b>
            </b-button>
          </b-nav-item>

          <!--<b-nav-item v-if="isLogged" :href="myProfileUrl" class="nav-networking">
           <b-button variant="link">
            <img src="~@/assets/images/platform/networking-icon.svg" alt="networking" class="mb-1" />
            <b>NETWORKING</b>
          </b-button>
        </b-nav-item>-->

          <b-nav-item-dropdown
            v-if="isLogged && hasNetworkingAccess && isNetworkingEnabled && !preCongressMode"
            class="nav-networking"
            :class="{ 'link-active': isNetworkingLinkActive }"
          >
            <!-- class="link-active" when user's in the networking area -->
            <template slot="button-content">
              <img src="~@/assets/images/platform/networking-icon.svg" alt="networking" class="mb-2" />
              <span v-if="hasUnreadNotifications" class="b-avatar-badge badge-primary"> {{ unreadNotifications }} </span
              ><!-- badge when new unread messages -->
              <b style="color: #ae1022">NETWORKING</b>
            </template>
            <b-dropdown-item :to="`/${eventRoute}/${chatParticipantConfig.route}/search`">Search</b-dropdown-item>
            <b-dropdown-item :to="`/${eventRoute}/${chatParticipantConfig.route}/messages`" :class="{ newMessage: hasUnreadNotifications }">
              <!-- add class="newMessage" when new & unread messages -->
              Messages <span v-if="hasUnreadNotifications" class="badge badge-danger ml-2">{{ unreadNotifications }}</span>
              <!-- badge when new unread messages -->
            </b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item v-if="false && isLogged" :href="myProfileUrl" target="_blank" class="nav-login">
            <b-button variant="link">
              <i class="las la-10x la-user-circle"></i>
              <span>
                <b>{{ firstName }}</b>
              </span>
            </b-button>
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <v-tour v-if="!isMobile" name="overview-tour" :steps="steps2" :callbacks="callbacks" :active="isTourActive"></v-tour>
    <v-tour v-if="!isMobile && (postCongressMode || preCongressMode)" name="nav-tour" :steps="steps" :callbacks="callbacksNav" :active="isTourNavActive"></v-tour>
    <v-tour v-if="!isMobile && !postCongressMode && !preCongressMode" name="navLive-tour" :steps="steps" :callbacks="callbacksNavLive" :active="isTourNavLiveActive"></v-tour>
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  import { login, destroyUser, logout } from 'src/services/auth';
  import TakeQuizzWidget from './TakeQuizzWidget';
  import CrossLinkWidget from './CrossLinkWidget';
  // import SearchLight from 'src/components/Search/SearchLight'
  import openModalMixin from 'src/mixins/openModalMixin';
  import toursManagerMixin from 'src/mixins/toursManagerMixin';
  import { constants } from 'src/constants';

  export default {
    name: 'Navbar',
    components: {
      TakeQuizzWidget,
      CrossLinkWidget,
      // SearchLight
    },
    mixins: [openModalMixin, toursManagerMixin],
    data() {
      return {
        searchInput: null,
        home_link_text: null,
        abstracts_link_text: null,
        abstracts_link_url: null,
        exhibition_link_text: null,
        exhibition_link_url: null,
        esc_stand_link_text: null,
        esc_stand_link_url: null,
        esc_stand_link_target: '_self',
        support_link_text: null,
        support_link_url: null,
        isCollapsedSection: {},
        isTourActive: false,
        isTourNavActive: false,
        isTourNavLiveActive: false,
        tourIndex: 0,
        steps: [],
        steps2: [
          {
            target: '[data-v-step-overview2="0"]',
            header: {},
            content: `Welcome, Please sign with your My ESC account. Don't have one? Create yours now in just one minute (for free).`,
            params: {
              placement: 'bottom',
              enableScrolling: false,
              highlight: true,
            },
          },
        ],
        callbacksNav: {
          onSkip: () => this.onTourCompleted('nav'),
          onFinish: () => this.onTourCompleted('nav'),
        },
        callbacksNavLive: {
          onSkip: () => this.onTourCompleted('navLive'),
          onFinish: () => this.onTourCompleted('navLive'),
        },
        callbacks: {
          onSkip: () => this.onTourCompleted('overview'),
          onFinish: () => this.onTourCompleted('overview'),
        },
        toursEnd: false,
      };
    },
    computed: {
      publicPath() {
        return process.env.BASE_URL;
      },
      returnUrl() {
        return window.location.origin + this.$route.fullPath;
      },
      myProfileUrl() {
        return process.env.VUE_APP_MY_PROFILE_URL;
      },
      userProfileUrl() {
        return `${process.env.VUE_APP_ESC365_BASE_URL}/userprofile`;
      },
      becomeMemberUrl() {
        return `${process.env.VUE_APP_ESCARDIO_BASE_URL}/The-ESC/Membership`;
      },
      registerUrl() {
        return `${process.env.VUE_APP_SF_COMMUNITY_BASE_ACCESS_URL}/Account/ESCRegister`;
      },
      esc365Url() {
        return `${process.env.VUE_APP_ESC365_BASE_URL}`;
      },
      isMobile() {
        return this.$screen.breakpoint === 'xs' || this.$screen.breakpoint === 'sm' || this.$screen.breakpoint === 'md'; //window.innerWidth <= 768; // Détecte les écrans de 768px de large ou moins comme étant des appareils mobiles
      },
      myAccountUrl() {
        return `${process.env.VUE_APP_SF_COMMUNITY_BASE_ACCESS_URL}/MyESC/`;
      },
      sloUrl() {
        return process.env.VUE_APP_SLO_URL;
      },
      ...mapGetters(['isLogged', 'currentUser', 'fullName']),
      ...mapState({
        appVersion: (state) => state.app.version,
        eventId: (state) => state.app.eventId,
        eventRoute: (state) => state.app.eventRoute,
        firstName: (state) => state.auth.user.firstName,
        lastName: (state) => state.auth.user.lastName,
        preCongressMode: (state) => state.app.preCongressMode,
        postCongressMode: (state) => state.app.postCongressMode,
        confCmsData: (state) => state.app.confCmsData,
        unreadNotifications: (state) => state.app.unreadNotifications,
        chatParticipantConfig: (state) => state.app.chatParticipantConfig,
        ressourcesMovedTo365: (state) => state.app.ressourcesMovedTo365,
        industryStandsSearch: (state) => state.app.industryStandsSearch,
        indexAbstracts: (state) => state.app.indexAbstracts,
        indexIndustries: (state) => state.app.indexIndustries,
        indexLiveSessions: (state) => state.app.indexLiveSessions,
        indexOnDemandSessions: (state) => state.app.indexOnDemandSessions,
        indexSpeakers: (state) => state.app.indexSpeakers,
      }),
      hasUnreadNotifications() {
        return this.unreadNotifications > 0;
      },
      isNetworkingLinkActive() {
        return this.$route.path.includes('/networking');
      },
      isNetworkingEnabled() {
        //console.log(`chatParticipantConfig ${JSON.stringify(this.chatParticipantConfig)}`);
        return this.chatParticipantConfig && this.chatParticipantConfig.eventId && this.chatParticipantConfig.eventId == this.eventId;
      },
      hasNetworkingAccess() {
        return this.chatParticipantConfig && this.chatParticipantConfig.route;
      },
    },
    watch: {
      confCmsData: {
        immediate: true,
        handler(newVal) {
          if (newVal && newVal.navbar_blocks) {
            this.initializeCollapsedSections(newVal.navbar_blocks);
          }
        },
      },
    },

    mounted() {
      if (!this.isMobile) {
        if (!this.isTourActive) {
          this.isTourActive = true;
          if (!this.tours || !this.tours.overview) {
            this.$nextTick(() => {
              this.$tours['overview-tour'].start();
            });
          }
        }
        if (!this.isTourNavActive) {
          this.isTourNavActive = true;
          if (!this.tours || !this.tours.nav) {
            this.generateTourSteps();
            if (this.steps && this.steps.length > 0) {
              this.$nextTick(() => {
                this.$tours['nav-tour'].start();
              });
            }
          }
        }
        if (!this.isTourNavLiveActive) {
          this.isTourNavLiveActive = true;
          if (!this.tours || !this.tours.navLive) {
            this.generateTourSteps();
            if (this.steps && this.steps.length > 0) {
              this.$nextTick(() => {
                this.$tours['navLive-tour'].start();
              });
            }
          }
        }
      }
    },
    methods: {
      login() {
        login(this.returnUrl, true);
      },
      destroyUserAndLogout() {
        destroyUser();
        logout(this.returnUrl);
      },
      initializeCollapsedSections(navbarBlocks) {
        const sections = {};
        navbarBlocks?.forEach((navEntry) => {
          sections[navEntry.link_text] = null;
        });
        this.isCollapsedSection = sections;
      },
      handleToggleClick(section, event) {
        event.preventDefault();
        event.stopPropagation()
        this.toursEnd = true;
        if (this.isCollapsedSection[section] == null) {
          this.$set(this.isCollapsedSection, section, false);
        } else {
          this.isCollapsedSection[section] = !this.isCollapsedSection[section];
        }

        // Close other sections when one is opened
        for (let key in this.isCollapsedSection) {
          if (key !== section && this.isCollapsedSection[key] === false) {
            this.isCollapsedSection[key] = true;
          }
        }
      },
      clickToCollapse(navEntry) {
        if (!navEntry.children || navEntry.children.length == 0) {
          const isCollapsed = true;
          for (let key in this.isCollapsedSection) {
            this.isCollapsedSection[key] = isCollapsed;
          }
        }
         else {
          event.stopPropagation();
        }
      },
      displayCommunity() {
        return this.eventId === constants.ESC_2024_EVENT_ID || this.eventId == constants.ESC_2023_EVENT_ID;
      },
      generateTourSteps() {
        this.steps = [];
        this.tourIndex = 0;
        this.confCmsData.navbar_blocks?.forEach((navEntry) => {
          if (navEntry.tour_text && navEntry.isDisplayed) {
            navEntry.tourIndex = this.tourIndex;
            this.steps.push({
              target: `[data-v-step="${this.tourIndex}"]`,
              header: {},
              content: navEntry.tour_text,
              params: {
                placement: 'bottom',
                enableScrolling: false,
                highlight: true,
              },
            });
            this.tourIndex++;
          }
        });
      },
    },
  };
</script>

<style scoped>

.live-icon {
  display: inline-block;
  position: relative;
  top: calc(50% - 5px);
  background-color: red;
  width: 10px;
  height: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1); /* Remplacement de `rgba(black, 0.1)` par `rgba(0, 0, 0, 0.1)` */
  border-radius: 50%;
  z-index: 1;
  margin-right: 5px;
}
.live-icon:before {
  content: "";
  display: block;
  position: absolute;
  background-color: rgba(255, 0, 0, 0.6); /* Utilisation de rgba avec des valeurs RGB */
  width: 100%;
  height: 100%;
  border-radius: 50%;
  animation: live 2s ease-in-out infinite;
  z-index: -1;
}

@keyframes live {
  0% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(3.5, 3.5);
    background-color: rgba(255, 0, 0, 0); /* Utilisation de rgba avec des valeurs RGB */
  }
}


  .nav_item {
    position: relative;
  }

  .subnav__collapsed {
    position: absolute;
    width: 100%;
    left: 0;
    top: 100%;
  }

  .navbar {
    padding: 0;
  }
  .navbar-brand {
    z-index: 1;
  }
  .navbar-expand-xl .navbar-nav {
    font-family: 'Interstate WGL Black';
  }

  @media screen and (min-width: 1200px) {
    #nav-collapse {
      margin-left: 50px;
    }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row;
      justify-content: center;
      align-items: center;
      /*margin-right: auto;*/
      /*margin-left: auto;*/
    }
    .navbar-expand-xl .navbar-nav .nav-link {
      padding-right: 0.45rem;
      padding-left: 0.45rem;
      max-height: 40px;
    }
    /*.navbar-nav .nav-item.nav-login {
      position: absolute;
      right: 0;
    }*/
  }
  @media screen and (min-width: 768px) {
    .navbar-nav {
      flex-direction: row;
      justify-content: center;
    }
  }
  @media screen and (max-width: 767px) {
    .navbar-nav {
      display: block;
      text-align: center;
    }
  }
  /* NEW*/
  .navbar {
    overflow: visible; /* horreur totale en cas de quiz ajouté à la navbar */
  }
  .rightNav {
    margin-right: inherit !important;
  }
  .nav-networking {
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
  }
  .nav-networking button,
  .dropdown.nav-networking,
  .nav-login b {
    font-size: 0.6em;
    letter-spacing: 1px;
  }
  .nav-networking img {
    height: 25px;
    display: block;
    margin: auto;
  }

  .nav-login b {
    position: relative;
    top: -2px;
    line-height: 1;
    display: block;
  }
  .nav-login span {
    display: block;
    line-height: 1;
  }
  .nav-login i {
    color: #ae1022;
    display: block;
    margin-bottom: 0.5rem;
  }

  .btn-link:hover {
    text-decoration: none;
  }
  @media (max-width: 1200px) {
    .nav-networking {
      border-left: 0;
    }
    .rightNav {
      border-top: 1px solid #e5e5e5;
    }
  }
  @media (max-width: 768px) {
    .rightNav {
      border-top: 1px solid #e5e5e5;
    }

    .rightNav li {
      width: 50%;
      float: left;
    }
    .rightNav li ul li {
      width: inherit;
      float: none;
    }
    .nav-login a {
      padding: 0;
    }
    .nav-networking img {
      margin-top: 5px;
    }

    .navbar-365 .btn-membership {
      display: none;
    }
  }

  .navbar-365 {
    background: #fff;

    font-family: 'Interstate WGL Regular';
    z-index: 1000;
    background: #fff;
    top: 0;
  }

  .navbar-365 img {
    height: 25px;
    width: 50px;
  }

  .btn-membership {
    color: #fff;
    background-color: #ae1022;
    border-color: #ae1022;
  }

  .btn-membership:hover,
  .btn-membership:focus,
  .btn-membership:active {
    color: #fff;
    background-color: #720915;
    border-color: #720915;
  }

  .btn-link {
    color: #ae1022;
  }

  .btn-link:hover {
    color: #720915;
  }

  .btn-link:hover,
  .btn-link:focus,
  .btn-link:active,
  .btn-link:visited {
    text-decoration: none;
    box-shadow: none;
  }

  .btn-link span {
    color: #ae1022 !important;
  }

  .btn-link span.colorBlack {
    color: #000 !important;
  }

  @media (min-width: 992px) {
    .navbar-365 {
      height: 70px;
      padding: 1rem 15px;
    }
  }

  @media (max-width: 425px) {
    .subnav__collapsed {
      position: relative;
    }
  }
</style>

<style scoped>
  ::v-deep .dropdown-toggle {
    color: #343a40;
    background-color: #fff;
    border-color: #343a40;
    border-radius: 50rem !important;
    border: 2px solid;
  }

  ::v-deep .dropdown-toggle:hover {
    background-color: #eee;
    border-color: #eee;
  }

  .dropdown-menu {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    border: 0 !important;
  }

  :focus-visible {
    outline-width: 0 !important;
  }

  .nav-networking .badge-primary {
    position: absolute;
    min-height: 2em;
    min-width: 2em;
    padding: 0.25em;
    border-radius: 10em;
    z-index: 1;
    top: -0.5em;
    right: 20%;
    text-align: center;
    background-color: #ae1022;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.25) !important;
  }
  .nav-networking .badge-danger {
    background: #ae1022;
    border-radius: 10em;
    font-size: 70%;
  }

  @media (max-width: 768px) {
    .nav-networking .badge-primary {
      right: 40%;
      top: 0;
    }
  }
  @media (max-width: 576px) {
    .nav-networking .badge-primary {
      right: 30%;
      top: 0;
    }
  }
  @media (max-width: 1200px) {
    .navbar-nav .nav-networking .dropdown-menu {
      position: absolute;
      width: 100%;
    }
  }
  @media (min-width: 1200px) {
    .nav-networking.link-active a.nav-link::before {
      content: '';
      position: absolute;
      width: 70px;
      height: 5px;
      background: #ae1022;
      display: block;
      bottom: -20px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
    }
    .nav-networking .badge-primary {
      position: absolute;
      min-height: 2em;
      min-width: 2em;
      padding: 0.25em;
      border-radius: 10em;
      z-index: 1;
      top: -0.5em;
      right: 20%;
      text-align: center;
      background-color: #ae1022;
      box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.25) !important;
    }
    .navbar-nav .nav-login .btn {
      max-width: 100px !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
  .navbar-nav .nav-link.router-link-active:before {
    width: 10px !important;
    height: 10px !important;
    top: 0 !important;
  }
</style>
