import { constants } from 'src/constants';
import { MediaApiService } from 'src/services/media-api';
import { store } from 'src/store';
import { LOGIN, LOGOUT } from 'src/store/actions';
import Cookies from 'js-cookie';

export function getUser() {
  try {
    return JSON.parse(window.localStorage.getItem(constants.LOCAL_USER_KEY));
  } catch (e) {
    return null;
  }
}

export function saveUser(user) {
  try {
    window.localStorage.setItem(constants.LOCAL_USER_KEY, JSON.stringify(user));
  } catch (e) {
    return;
  }
}

export function destroyUser() {
  try {
    window.localStorage.removeItem(constants.LOCAL_USER_KEY);
  } catch (e) {
    return;
  }
}

export function login(backUrl, forceCheckAccess) {
  //window.location = process.env.VUE_APP_SSO_URL.concat(`?ReturnUrl=${encodeURI(backUrl)}`);
  //console.log('login ' + forceSalesforceCheckAccess);

  //add forceCheckAccess query parameter
  //Diabled for modal rework
  forceCheckAccess = false;
  if (forceCheckAccess) {
    var url = new URL(backUrl);
    url.searchParams.append('fca', 1);
    backUrl = url.href;
  }
  //Redirect to login
  window.location = process.env.VUE_APP_SSO_URL.concat(`?ReturnUrl=${encodeURIComponent(backUrl)}`);
}

export function redirectNoAccess(redirectUrl, backUrl) {
  window.location = redirectUrl.concat(`&ReturnUrl=${encodeURIComponent(backUrl)}`);
}

export function redirectMaintenance() {
  window.location = 'https://www.escardio.org/static-resources/';
}

export function redirectErrorNotFound() {
  window.location = `${window.location.origin}/error404.html`;
}

export function redirectErrorNoAccess() {
  window.location = `${window.location.origin}/errorNoAccess.html`;
}

export function redirectError() {
  window.location = `${window.location.origin}/error.html`;
}

export function register(/*backUrl*/) {
  window.location = store.state.app.registrationLink; //.concat(`&forceregister=1&originurl=${encodeURIComponent(backUrl)}`);
}

export function refreshAccess(backUrl) {
  window.location = process.env.VUE_APP_SF_CHECK_ACCESS_URL.concat(`?originurl=${encodeURIComponent(backUrl)}`);
}

export function logout(backUrl) {
  window.location = process.env.VUE_APP_SLO_URL.concat(`?ReturnUrl=${backUrl}`);
}

export function hasExpired() {
  const user = getUser();
  return !user || new Date().getTime() / 1000 >= parseInt(user.expires) - 300;
}

export async function checkAuthentication() {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('token')) {
    // If a token is in query string, dispatch login action
    await store.dispatch(LOGIN, urlParams);
  }

  const expired = hasExpired();
  if (expired) await store.dispatch(LOGOUT);

  const loggedIn = getUser();
  const backUrl = window.location.href;

  if (expired || !loggedIn) {
    login(backUrl);
  }

  MediaApiService.setHeader();

  /*
  return new Promise((resolve, reject) => {
    // [ ... ]
 
    checkHasAccess(loggedIn, backUrl).then((hasAccess) => {
      if (hasAccess)
        return resolve();
      else
        return reject(false);
    });
  });
  */
}

export function processEncryptedEscID(eescid) {
  if (eescid) Cookies.set('eescid', eescid);
}

/*
export function loginAndCheckAccessFallback(backUrl) {
  //console.log('loginAndCheckAccessFallback backUrl'+backUrl);
  //console.log('loginAndCheckAccessFallback registrationLink'+store.state.app.registrationLink);
  //console.log('loginAndCheckAccessFallback '+encodeURIComponent(backUrl+'&fca=1'));

  //window.location = process.env.VUE_APP_SSO_URL.concat(`?ReturnUrl=${encodeURIComponent(backUrl+'&fca=1')}`);

   
  //Case 1: login sf redirect login platform (hpa lost)
  //backUrl = process.env.VUE_APP_SSO_URL.concat(`?ReturnUrl=${encodeURIComponent(backUrl)}`);
  //window.location = store.state.app.registrationLink.concat(`&forceregister=0&originurl=${encodeURIComponent(backUrl)}`);
  

  //Case 2: login platform redirect sf
  //backUrl = store.state.app.registrationLink.concat(`&forceregister=0&originUrl=${encodeURIComponent(backUrl)}`);
  //window.location = process.env.VUE_APP_SSO_URL.concat(`?ReturnUrl=${encodeURIComponent(backUrl)}`);

  //Case 3: login platform redirect platform force check access
  /backUrl = backUrl + '&fca=1';
  //window.location = process.env.VUE_APP_SSO_URL.concat(`?ReturnUrl=${encodeURIComponent(backUrl)}`);

  //backUrl = store.state.app.registrationLink.concat(`&originUrl=${encodeURIComponent(backUrl)}`);
  //window.location = process.env.VUE_APP_SSO_URL.concat(`?ReturnUrl=${encodeURIComponent(backUrl)}`);
}
*/

/*
export async function checkHasAccess(eventId, loggedIn, backUrl) {
  //console.log('checkHasAccess');
  //console.debug(`%ccheckAccess eventID: eventId`,consoleColors.misc);
  //if (!user) user = getUser();
  let hasAccess = false;

  const enableFallback = process.env.VUE_APP_HAS_ACCESS_TOKEN_FALLBACK === 'true';
  const urlParams = new URLSearchParams(window.location.search);

  var hasAccessResponse = await MediaApiService.userHasAccess(eventId);
  if (hasAccessResponse && hasAccessResponse.data) {
    hasAccess = hasAccessResponse.data.hasAccess;
  }

  if (!hasAccess && enableFallback) {
    hasAccess = await checkQueryPlatformAccessFallback(loggedIn, hasAccessResponse.data.returnUrl, backUrl);
    // QueryHelper.removeCheckQueryPlatformAccessFallbackProcessParameters();
  }

  if (!hasAccess) {
    if (urlParams.has('hpa')) {
      redirectErrorNoAccess();
    }
    else {
      redirectNoAccess(hasAccessResponse.data.returnUrl, backUrl);
    }
  }

  return hasAccess;
}

export async function checkQueryPlatformAccessFallback(user, checkAccessurl, backUrl) {
  //console.log('checkQueryPlatformAccessFallback ' + checkAccessurl + ' ' + backUrl);
  return checkQueryPlatformAccessFallbackToken(user, checkAccessurl, backUrl);
}

export async function checkQueryPlatformAccessFallbackToken(user, forceLogoutOnDiscrepency, checkAccessurl, backUrl) {
  //console.log('checkQueryPlatformAccessFallback ' + checkAccessurl + ' ' + backUrl);
  let hasAccess = store.state.auth.hasAccess;
  if (!user) user = getUser();
  if (user && user.escId) {
    //let hasAccess = user.hasAccess;
    if (!hasAccess) {
      let hpa; //decoded hpa token
      let qhpa = QueryHelper.getParameterByName('hpa', null); //query string encoded hpa token
      //console.log('qhpa ' + qhpa);

      if (qhpa) {
        let bhpa = user.escId;
        //platform hpa token 
        //console.log('bhpa ' + bhpa);
        if (bhpa) {
          //Decode hpa token
          try {
            let dataHpa = await MediaApiService.decryptEscId(qhpa);
            //console.log('dataHpa ' + JSON.stringify(dataHpa));

            if (dataHpa && dataHpa.data) hpa = dataHpa.data.escId;

            //console.log('hpa ' + hpa);
            //hpa = atob(qhpa);
          }
          catch (atob_error) {
            //console.log('invalid shpa');
            //console.log(atob_error);
          }

          if (hpa === bhpa) {
            //console.log('access granted using hpa');
            //QueryHelper.removeCheckQueryPlatformAccessFallbackProcessParameters();
            hasAccess = true;
            trackAccessGranted(user,null);
          }
          else if (forceLogoutOnDiscrepency && hpa && bhpa && hpa !== bhpa) {
            //console.log('invalid hpa provided, force logout');
            destroyUser();
            if (checkAccessurl) {
              await logout(checkAccessurl.concat(`&originurl=${encodeURIComponent(backUrl)}`));
            }
            else {
              let newUrl = new URL(backUrl);
              newUrl.searchParams.delete('hpa');
              //console.log('force logout url ' + newUrl.href);
              await logout(newUrl.href);
            }
          }
          else {
            //console.log('access denied using hpa');
            trackAccessDenied(user,null);
          }
        }
        
        else {
          //console.log('no escid found');
        }
        
      }
      
      else {
        //console.log('no hpa found');
      }
      
      await store.commit(SET_HAS_ACCESS, hasAccess);
    }
  }
  return hasAccess;
}

export async function checkAccess(user, forceRegister, backUrl, enableCheckAccessFromSalesforce) {
  console.debug(`%ccheckAccess eventID: ${store.state.app.eventId}`, consoleColors.misc);

  //const checkAcessProps = {user:user,forceRegister:forceRegister,backUrl:backUrl,enableCheckAccessFromSalesforce:enableCheckAccessFromSalesforce};
  //this.$appInsights?.trackEvent({ name: 'checkAccess', properties: checkAcessProps });

  //if (!user) user = getUser();
  //console.log('checkAccess user '+JSON.stringify(user));
  let hasAccess = store.state.auth.hasAccess;
  //console.log('checkAccess hasAccess: ' + hasAccess);
  if (!hasAccess) {
    const enableFallback = process.env.VUE_APP_HAS_ACCESS_TOKEN_FALLBACK === 'true';
    const eventId = store.state.app.eventId;
    var hasAccessResponse = await MediaApiService.userHasAccess(eventId);
    //console.log('hasAccessResponse ' + JSON.stringify(hasAccessResponse));
    if (hasAccessResponse && hasAccessResponse.data) {
      hasAccess = hasAccessResponse.data.hasAccess;
      await store.commit(SET_HAS_ACCESS, hasAccess);

      trackAccessGranted(null,eventId);
    }
    //console.log('enableFallback ' + enableFallback);
    if (!hasAccess && enableFallback && enableCheckAccessFromSalesforce) {
      await checkAccessFromSalesforce(hasAccessResponse.data.returnUrl, forceRegister, backUrl);
    }
  }
}

export async function checkAccessFromSalesforce(checkAccessFromSalesforceUrl, forceRegister, backUrl) {
  //console.log('checkAccessFromSalesforce link: ' + store.state.app.registrationLink);
  //console.log('checkAccessFromSalesforce backUrl: ' + backUrl);
  //let registrationurl = store.state.app.registrationLink;
  //registrationurl = 'https://devpartial-escardio--community.cs173.force.com/ESCCongressCheckRegistration?eventid=a3v3E000000EZbCQAW';
  window.location = checkAccessFromSalesforceUrl.concat(`&forceregister=${forceRegister ? "1" : "0"}&originurl=${encodeURIComponent(backUrl)}`);
  //window.location = store.state.app.registrationLink.concat(`&returnUrl=${encodeURIComponent(backUrl)}`);
}
*/

/*
function trackAccessGranted(user,eventId)
{
  if(!user) user = getUser();
  if(!eventId) eventId = store.state.app.eventId;
  //const checkAcessProps = {user:user,event:eventId};
  //this.$appInsights?.trackEvent({ name: 'AccessGranted', properties: checkAcessProps });
}

function trackAccessDenied(user,eventId)
{
  if(!user) user = getUser();
  if(!eventId) eventId = store.state.app.eventId;
  //const checkAcessProps = {user:user,event:eventId};
  //this.$appInsights?.trackEvent({ name: 'AccessDenied', properties: checkAcessProps });
}
*/

/*
export async function refreshHasAccessFromFallBackUrl() {
  //console.log('refreshHasAccessFromFallBackUrl link: ' + store.state.app.registrationLink);
  let dataHpa = await MediaApiService.userHasAccessFallBack(store.state.app.registrationLink);
  //console.log('dataHpa ' + JSON.stringify(dataHpa));

  //window.location =  store.state.app.registrationLink.concat(`&returnUrl=${encodeURIComponent(backUrl)}`);
}
*/
