import { render, staticRenderFns } from "./SessionButton.vue?vue&type=template&id=a23a6c72&scoped=true"
import script from "./SessionButton.vue?vue&type=script&lang=js"
export * from "./SessionButton.vue?vue&type=script&lang=js"
import style0 from "./SessionButton.vue?vue&type=style&index=0&id=a23a6c72&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a23a6c72",
  null
  
)

export default component.exports